<!--
  //nuxt-ui/components/pages/region-tickets/HubSpotForm.vue.vue
  -->

<script setup lang="ts">
import BaseSpinner from "~/nuxt-ui/components/base/BaseSpinner.vue";

type Props = {
  region?: string;
  portalId?: string;
  formId: string;
  src: string;
};
const props = withDefaults(defineProps<Props>(), {
  region: "eu1",
  portalId: "144680905",
});

const isLoading = ref(true);

onMounted(() => {
  const script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("charset", "utf-8");
  script.setAttribute("src", props.src);
  document.head.appendChild(script);

  script.onload = () => {
    isLoading.value = false;
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: props.region,
        portalId: props.portalId,
        formId: props.formId,
        target: "#hubspot-form",
      });
    }
  };
});
</script>

<template>
  <div>
    <div v-if="isLoading" class="flex justify-center items-center">
      <base-spinner class="mt-12" :size="30"></base-spinner>
    </div>
    <div id="hubspot-form" v-if="!isLoading"></div>
  </div>
</template>
<style scoped></style>
